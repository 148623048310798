import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { motion } from "framer-motion";
import Contact from "../../img/contactthebuckhorn.svg";
import ContactBanner from "../../img/outside.jpg";
import { graphql } from "gatsby";
import { FaFacebookSquare } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

function encode(data) {
	console.log(data);
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
		.join("&");
}

export default class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isValidated: false };
	}

	componentDidMount() {
		console.log(this.props.data);
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": form.getAttribute("name"),
				...this.state,
			}),
		})
			.then(() => navigate(form.getAttribute("action")))
			.catch((error) => alert(error));
	};

	render() {
		//const { frontmatter } = data.markdownRemark;

		return (
			<Layout>
				<div
					className="full-width-image-container margin-top-0 mb0"
					style={{
						background: `url(${ContactBanner})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				>
					<div
						className="mx-auto relative"
						style={{
							backgroundSize: "cover",
							backgroundPosition: "top center",
							background:
								"linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
							height: "100%",
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							padding: "100px 0",
						}}
					>
						<motion.div
							style={{ originY: 1 }}
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.8,
									opacity: 0,
								},
								visible: {
									scale: 1,
									opacity: 1,
									transition: {
										delay: 0.6,
									},
								},
							}}
						>
							<div
								className="has-text-weight-bold is-size-1"
								style={{
									backgroundColor: "rgb(166, 33, 3)",
									color: "white",
									padding: "1rem",
								}}
							>
								<div
									style={{
										display: "flex",
										lineHeight: "1",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
										padding: "0px",
										height: "auto",
										position: "relative",
									}}
								>
									<img
										src={Contact}
										alt="Buckhorn Supper Club"
										className="mb0 svg-logo-menu"
									/>
								</div>
							</div>
						</motion.div>
					</div>
				</div>

				<section className="section get-in-touch contact-page is-clearfix contact-top">
					<div className="container">
						<div class="section">
							<div className="columns">
								<div className="column is-4" data-aos="fade">
									<motion.div
										style={{ originY: 1 }}
										initial="hidden"
										animate="visible"
										variants={{
											hidden: {
												scale: 0.8,
												opacity: 0,
											},
											visible: {
												scale: 1,
												opacity: 1,
												transition: {
													delay: 0.8,
												},
											},
										}}
									>
										<div className="box-item ">
											<FaMapMarkerAlt className="contact-icons" />
											<h4>Address</h4>
											<p>
												<a
													href="https://maps.google.com/maps?ll=42.842261,-88.976505&z=17&t=m&hl=en&gl=US&mapclient=embed&daddr=Buckhorn%20Supper%20Club%2011802%20N%20Charley%20Bluff%20Rd%20Milton%2C%20WI%2053563@42.84226109999999,-88.97650519999999"
													target="_blank"
													rel="noopener noreferrer"
												>
													11802 N Charley Bluff Rd,
													<br /> Milton, WI 53563
												</a>
											</p>
										</div>
									</motion.div>
								</div>

								<div className="column is-4" data-aos="fade">
									<motion.div
										style={{ originY: 1 }}
										initial="hidden"
										animate="visible"
										variants={{
											hidden: {
												scale: 0.8,
												opacity: 0,
											},
											visible: {
												scale: 1,
												opacity: 1,
												transition: {
													delay: 1,
												},
											},
										}}
									>
										<div className="box-item ">
											<FaPhoneAlt className="contact-icons" />
											<h4>Phone</h4>
											<p>
												<a href="tel:16088682653">(608) 868-2653</a>
											</p>
										</div>
									</motion.div>
								</div>

								<div className="column is-4" data-aos="fade">
									<motion.div
										style={{ originY: 1 }}
										initial="hidden"
										animate="visible"
										variants={{
											hidden: {
												scale: 0.8,
												opacity: 0,
											},
											visible: {
												scale: 1,
												opacity: 1,
												transition: {
													delay: 1.2,
												},
											},
										}}
									>
										<div className="box-item ">
											<FaFacebookSquare className="contact-icons" />
											<h4>Facebook</h4>
											<a
												href="https://www.facebook.com/thebuckhorn/"
												target="_blank"
												rel="noopener noreferrer"
											>
												facebook.com/thebuckhorn
											</a>
											<p></p>
										</div>
									</motion.div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section">
					<div className="container">
						<div className="columns">
							<div className="column is-8 is-offset-2">
								<motion.div
									style={{ originY: 1 }}
									initial="hidden"
									animate="visible"
									variants={{
										hidden: {
											scale: 0.8,
											opacity: 0,
										},
										visible: {
											scale: 1,
											opacity: 1,
											transition: {
												delay: 1.4,
											},
										},
									}}
								>
									<div className="content">
										<h1 className="red">Contact</h1>
										<form
											name="contact"
											method="post"
											action="/contact/thanks/"
											data-netlify="true"
											data-netlify-honeypot="bot-field"
											onSubmit={this.handleSubmit}
										>
											{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
											<input type="hidden" name="form-name" value="contact" />
											<div hidden>
												<label>
													Don’t fill this out:{" "}
													<input
														name="bot-field"
														onChange={this.handleChange}
													/>
												</label>
											</div>
											<div className="field">
												<label className="label" htmlFor={"name"}>
													Your name
												</label>
												<div className="control">
													<input
														className="input"
														type={"text"}
														name={"name"}
														onChange={this.handleChange}
														id={"name"}
														required={true}
													/>
												</div>
											</div>
											<div className="field">
												<label className="label" htmlFor={"email"}>
													Email
												</label>
												<div className="control">
													<input
														className="input"
														type={"email"}
														name={"email"}
														onChange={this.handleChange}
														id={"email"}
														required={true}
													/>
												</div>
											</div>
											<div className="field">
												<label className="label" htmlFor={"message"}>
													Message
												</label>
												<div className="control">
													<textarea
														className="textarea"
														name={"message"}
														onChange={this.handleChange}
														id={"message"}
														required={true}
													/>
												</div>
											</div>
											<div className="field">
												<button className="button is-link" type="submit">
													Send
												</button>
											</div>
										</form>
									</div>
								</motion.div>
							</div>
						</div>
					</div>
				</section>

				<div className="map-wrapper">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2925.448552030816!2d-88.97869924858836!3d42.84226107905509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880612de5c874071%3A0x2533bfe34c048116!2sBuckhorn%20Supper%20Club!5e0!3m2!1sen!2sus!4v1598417897061!5m2!1sen!2sus"
						width={600}
						height={450}
						frameBorder={0}
						style={{ border: 0 }}
						allowFullScreen
						aria-hidden="false"
						title="Buckhorn Supper Club Map"
					/>
				</div>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;

/*
export const pageQuery = graphql`
	query ContactPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
*/
